<template>
    <div id="Collect"> 
    <Header/>  
    
<body class=" bg-white">
<!-- header -->
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
        <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" @click="onBack">
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          我的收藏
        </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" >
        </a> 
      </div>
    </nav>      
<!-- End header -->
    <div class="nicolespacetop"></div>
      <div class="container">
    <table class="table  table-borderless align-items-center mb-0 ">
      <tbody>
        <tr @click="onCollectLessonEssay">
          <td class="ps-0">
            <div class="d-flex">
              <div>
                <img src="assets/img/ic_note_20.png" class="avatar avatar-22 rounded-0 me-2">
              </div>
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0  text-s text-dark">文章收藏</h6>
              </div>
            </div>
          </td>
          <td class="align-middle text-right pe-1">
            <i class="far fa-chevron-right text-light2"></i>
          </td>
        </tr>        
          <tr @click="onCollectLessonVideo">
          <td class="ps-0">
            <div class="d-flex">
              <div>
                <img src="assets/img/ic_audio_20.png" class="avatar avatar-22 rounded-0 me-2 ">
              </div>
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0  text-s text-dark">影音收藏</h6>
              </div>
            </div>
          </td>
          <td class="align-middle text-right pe-1">
            <i class="far fa-chevron-right text-light2"></i>
          </td>
        </tr>          
          <tr @click="onCollectLessonWord">
          <td class="ps-0 ">
            <div class="d-flex">
              <div>
                <img src="assets/img/ic_vocab_20.png" class="avatar avatar-22 rounded-0 me-2 ">
              </div>
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0  text-s text-dark">我的單字本</h6>
              </div>
            </div>
          </td>
          <td class=" text-right  pe-1">
            <i class="far fa-chevron-right text-light2"></i>
          </td>
        </tr>          
          <tr @click="onCollectLessonSentence">
          <td class="ps-0">
            <div class="d-flex">
              <div>
                <img src="assets/img/ic_me_note_32.png" class="avatar avatar-22 rounded-0 me-2">
              </div>
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-s text-dark">我的筆記</h6>
              </div>
            </div>
          </td>
          <td class="align-middle text-right pe-1" data-bs-toggle="modal" data-bs-target="#notice3">
            <i class="far fa-chevron-right text-light2"></i>
          </td>
        </tr>           
          <tr @click="onCollectExamGroup">
          <td class="ps-0">
            <div class="d-flex">
              <div>
                <img src="assets/img/ic_me_qhistery_32.png" class="avatar avatar-22 rounded-0 me-2">
              </div>
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-s text-dark">考題收藏</h6>
              </div>
            </div>
          </td>
          <td class="align-middle text-right pe-1" data-bs-toggle="modal" data-bs-target="#notice3">
            <i class="far fa-chevron-right text-light2"></i>
          </td>
        </tr>          
    
      </tbody>
    </table>
    </div>
     
 
<!-- footer -->
<div class="nicolespace"></div>
<div class="nicolespace"></div>
</body>
    <Footer/>
    </div>
</template>


<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'Collect',
    components: {
        Header,
        Footer,
    },
    data () {
    return { 
        }
    },
    methods: {
        changeToWebCallback(data){},
        onCollectLessonEssay(){
            //this.$JsBridge.callHandler('openWebView', `/collect_lesson_essay`, this.changeToWebCallback);
          this.$router.push(
              {
                  name : 'CollectLessonEssay',
              });
        },
        onCollectLessonVideo(){
            // this.$JsBridge.callHandler('openWebView', `/collect_lesson_video`, this.changeToWebCallback);
            this.$router.push(
              {
                  name : 'CollectLessonVideo',
              });
        },
        onCollectLessonWord(){
            // this.$JsBridge.callHandler('openWebView', `/collect_lesson_word`, this.changeToWebCallback);
            this.$router.push(
              {
                  name : 'CollectLessonWord',
              });
        },
        onCollectLessonSentence(){
            //this.$JsBridge.callHandler('openWebView', `/collect_lesson_sentence`, this.changeToWebCallback);
            this.$router.push(
              {
                  name : 'CollectLessonSentence',
              });
        },
        onCollectExamGroup(){   
            this.$router.push(
            {
                name : 'CollectExamGroup',
            });
        },
        setOnBack(){
            this.$JsBridge.callHandler('setOnback', '', this.onBack);
        },
        onBack(){    
            this.$router.push(
            {
                name : 'Mine',
            });
        },
    },
    mounted() {
    },
    created(){  
      if (this.$JsBridge.isAndroid) {
          this.setOnBack();
      } 
    }
}
</script>